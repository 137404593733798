import React from "react";
import SEO from "components/seo";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import CoinstoneLogo from "components/ui/logo/CoinstoneLogo";
import BrandName from "components/ui/logo/BrandName";
import styles from "./Home.module.scss";

const IndexPage = () => (
  <AppContentContainer spacing="extra">
    <SEO title="Home" />
    <CoinstoneLogo className={styles.logo} />
    <BrandName className={styles.brand} variant="contrast" />
    <h4 style={{ textAlign: "center" }}>
      This site is a work in progress, nothing much to see here yet!
    </h4>
  </AppContentContainer>
);

export default IndexPage;
